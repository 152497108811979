// Variabili globali //
let scrollPosition = window.scrollY;
const body = document.querySelector('body');
const headerD = document.querySelector('.header-desktop');
const headerM = document.querySelector('.header-mobile');
let headerDH = 0;
let headerMH = 0;
if (headerD) {
    headerDH = headerD.clientHeight;
}
if (headerM) {
    headerMH  = headerM.clientHeight;
}

// Custom import //
import './custom/autohide'; // Autohide

// Bootstrap //
import { Dropdown, Collapse, Modal, Tab, Offcanvas, } from 'bootstrap';

// Riposiziona Accordion Bootstrap //
const accordionWrapper = document.querySelector('.accordion.autoscroll');

if (accordionWrapper) {
    let accordionItems = accordionWrapper.querySelectorAll('.accordion-collapse');

    accordionItems.forEach((el) => {
        el.addEventListener('shown.bs.collapse', (e) => {
            let headerHeight = getHeaderOffset();
            let scrollOffset = accordionWrapper.scrollTop + el.parentNode.offsetTop - headerHeight;
            // console.log(scrollOffset);
            window.scroll({
                top: scrollOffset,
                left: 0,
                behavior: 'smooth'
            });
        })
    });
}

// Smooth Scroll //
import SmoothScroll from "smooth-scroll";

let smoothScroll = new SmoothScroll(".smooth", {
    updateURL: false,
    popstate: false,
    offset: headerDH,
    speed: 1000,
    speedAsDuration: true,
});

// Lightbox
import GLightbox from 'glightbox';
const lightbox = GLightbox({
    loop: true
});

// Single Lightbox
const singleLightbox = GLightbox({
    loop: false
});

const singleLightboxElements = document.querySelectorAll('.glightbox-single');
if (singleLightboxElements) {
    singleLightboxElements.forEach(function (trigger) {
        trigger.addEventListener('click', function (e) {
            e.preventDefault();
            let targetHref = this.getAttribute('href');
            singleLightbox.setElements([{
                'href': targetHref
            }]);
            singleLightbox.open();
        })
    })
}

// Plyr //
import Plyr from 'plyr';

// Video Player //
const vid_player = Plyr.setup('.video-player', {
    controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'fullscreen'
    ],
    youtube: {
        noCookie: true,
        rel: 0,
        showinfo: 0,
        modestbranding: 1
    },
    vimeo: {
        byline: false,
        portrait: false,
        title: false,
        speed: true,
        transparent: false
    }
});

// Video Player 4:3 //
const vid_43 = Plyr.setup('.video-player-4x3', {
    controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'fullscreen'
    ],
    ratio: '4:3',
    youtube: {
        noCookie: true,
        rel: 0,
        showinfo: 0,
        modestbranding: 1
    },
    vimeo: {
        byline: false,
        portrait: false,
        title: false,
        speed: true,
        transparent: false
    }
});

// Swiper //
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, Thumbs, EffectCreative, Grid } from 'swiper/modules';
Swiper.use([Navigation, Pagination, Autoplay, Thumbs, EffectCreative, Grid]);

// Homepage Slider //
const homepage_slides = new Swiper(".homepage-slides", {
    loop: true,
    spaceBetween: 0,
    // allowTouchMove: false,
    speed: 1000,
    // autoplay: {
    //     delay: 7000,
    // },
    pagination: {
        el: '.slides-pagination',
        type: 'bullets',
        clickable: true,
        bulletClass: 'bullet',
        bulletActiveClass: 'active'
    },
});

// Homepage Category Slider //
let categorySlider = null;

function initCategorySlider() {
    if (document.querySelector('.category-slider')) {
        if (window.innerWidth < 992) {
            if (categorySlider === null) {
                categorySlider = new Swiper(".category-slider", {
                    spaceBetween: 24,
                    loop: true,
                    autoHeight: false,
                    breakpoints: {
                        0: {
                            slidesPerView: 1.15,
                        },
                        576: {
                            slidesPerView: 2.15,
                        },
                        768: {
                            slidesPerView: 3.15,
                        },
                    },
                    pagination: {
                        el: ".category-pagination",
                        clickable: true,
                    },
                });
            }
        } else {
            if (categorySlider !== null) {
                categorySlider.destroy();
                categorySlider = null;
            }
        }
    }
}

// Call the initialization function when the page loads
initCategorySlider();

// Call the initialization function when the window is resized, debounce the function call
window.addEventListener("resize", initCategorySlider);

// Product Slider //
const productThumbs = new Swiper('.product-thumbs', {
    spaceBetween: 16,
    freeMode: true,
    watchSlidesProgress: true,
    slidesPerView: 6,
});

const productGallery = new Swiper('.product-gallery', {
    effect: "creative",
    creativeEffect: {
        prev: {
            shadow: true,
            translate: ["-120%", 0, -500],
        },
        next: {
            shadow: true,
            translate: ["120%", 0, -500],
        },
    },
    loop: true,
    slidesPerView: 1,
    navigation: {
        nextEl: ".product-nav.next",
        prevEl: ".product-nav.prev",
    },
    thumbs: {
        swiper: productThumbs,
    },
    on: {
        init: function () {
            playVideoOnActiveSlide(this);
        },
        slideChange: function () {
            pauseVideoOnPreviousSlide(this);
            playVideoOnActiveSlide(this);
        }
    }
});

// Video function for slider
function playVideoOnActiveSlide(swiper) {
    let activeSlide = swiper.slides[swiper.activeIndex];
    let video = activeSlide.querySelector('.swiper-slide video');

    if (video) {
        video.play();
    }
}
function pauseVideoOnPreviousSlide(swiper) {
    let previousSlide = swiper.slides[swiper.previousIndex];
    let video = previousSlide.querySelector('video');

    if (video) {
        video.pause();
    }
}

// Finish Slider //
const finishGallery = new Swiper('.finish-gallery', {
    loop: true,
    slidesPerView: 1,
    navigation: false,
    pagination: {
        el: '.finish-pagination',
        clickable: true,
    },
});

// Callouts Slider //
const calloutGallery = new Swiper('.callout-slider', {
    loop: true,
    slidesPerView: 1,
    navigation: false,
    pagination: {
        el: '.callout-pagination',
        clickable: true,
    },
});

// Related Slider //
const relatedSlider = new Swiper('.related-slider', {
    spaceBetween: 24,
    autoHeight: false,
    loop: false,
    watchOverflow: true,
    speed: 500,
    navigation: {
        nextEl: ".related-nav.next",
        prevEl: ".related-nav.prev",
    },
    breakpoints: {
        0: {
            slidesPerView: 2,
            slidesPerGroup: 2,
        },
        992: {
            slidesPerView: 3,
            slidesPerGroup: 3,
        },
        1200: {
            slidesPerView: 4,
            slidesPerGroup: 4,
        }
    }
});

// Offers Slider //
const offerSlider = new Swiper('.offers-slider', {
    spaceBetween: 24,
    autoHeight: false,
    loop: false,
    watchOverflow: true,
    speed: 500,
    navigation: {
        nextEl: ".offer-nav.next",
        prevEl: ".offer-nav.prev"
    },
    breakpoints: {
        0: {
            slidesPerView: 2,
            slidesPerGroup: 2,
        },
        992: {
            slidesPerView: 3,
            slidesPerGroup: 3,
        },
        1200: {
            slidesPerView: 4,
            slidesPerGroup: 4,
        }
    }
});

// Offers Slider //
const storesSlider = new Swiper('.stores-slider', {
    spaceBetween: 24,
    autoHeight: false,
    loop: true,
    watchOverflow: true,
    // pagination: {
    //     el: ".offer-pagination",
    //     clickable: true
    // },
    navigation: {
        nextEl: ".stores-nav.next",
        prevEl: ".stores-nav.prev"
    },
    breakpoints: {
        0: {
            slidesPerView: 2,
            slidesPerGroup: 2
        },
        992: {
            slidesPerView: 3,
            slidesPerGroup: 3
        },
        1200: {
            slidesPerView: 4,
            slidesPerGroup: 4
        }
    }
});

// Offers Slider //
const focusSlider = new Swiper('.focus-slider', {
    spaceBetween: 24,
    autoHeight: false,
    loop: false,
    watchOverflow: true,
    navigation: {
        nextEl: ".focus-nav.next",
        prevEl: ".focus-nav.prev"
    },
    breakpoints: {
        0: {
            slidesPerView: 2
        },
        768: {
            slidesPerView: 3
        },
        1200: {
            slidesPerView: 4
        }
    }
});

// Gallery Slider //
const gallerySlider = new Swiper('.gallery-slider', {
    slidesPerView: "auto",
    spaceBetween: 16,
    loop: true,
    navigation: {
        nextEl: ".gallery-nav.next",
        prevEl: ".gallery-nav.prev",
    }
});

// HOVER INTENT //
import './custom/hoverintent';

// Elementi menu
let menuItems = document.querySelectorAll('.nav-item');

// Riconoscimento device touch
function isTouchDevice() {
    return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
}

if (isTouchDevice() === true) {
    menuItems.forEach(function (it) {
        it.classList.add('touch-device');
    });
    // console.log('Touch Device');
} else {
    // imposta azioni enter ed exit
    let hi = new BZN.HoverIntent(menuItems, {
        // parametri
        onEnter: function (targetItem) {
            targetItem.classList.add('visible');
        },
        onExit: function (targetItem) {
            targetItem.classList.remove('visible');
        },

        // opzioni
        exitDelay: 400,
        interval: 100,
        sensitivity: 7,
    });
    // console.log('Not a Touch Device');
}

// TIPPY
import tippy from 'tippy.js';
tippy('[data-tippy-content]');

// Attiva tab dei form attraverso i trigger
const formTriggers = document.querySelectorAll('[data-form]');
const formTabs = document.querySelectorAll('[data-trigger]');

formTriggers.forEach(trig => {
    trig.addEventListener('click', event => {
        // event.preventDefault();

        // Get the data-form attribute
        const formType = trig.dataset.form;
        // console.log(formType);

        // Find the tab with the matching data-trigger value
        let matchingTab;
        formTabs.forEach(tab => {
            if (tab.dataset.trigger === formType) {
                matchingTab = tab;
            }
        });

        // Use Bootstrap's tab show method if tab is found
        if (matchingTab) {
            const bootstrapTab = new Tab(matchingTab);
            bootstrapTab.show();
        }
    });
});

// Credits //
document.addEventListener("DOMContentLoaded", function () {
    console.log('A DIGITAL PROJECT BY');
    console.log('oooo                                                                                                 \n\roooo                      oooo                                                                       \n\roooo                      oooo                                                                       \n\roooo                                                                                                 \n\roooo                                                                                                 \n\roooo    oooooo            oooo      oooooooooooooooo         ooooooooo               oooooooooo      \n\rooooooooZooooooooo        oooo     ooooooooooooooooo      ooooooooooooooo         oooooooooooooooo   \n\roooooooo      ooooo       oooo                ooooo     ooooo         ooooo     ooooo          ooooo \n\rooooo           ooooo     oooo              ooooo      ooooo           ooooo   oooo             oooo \n\roooo             oooo     oooo             ooooo       ooooooooooooooooooooo   oooo              oooo\n\roooo             oooo     oooo           oooEo         oooo                    oooo              oooo\n\roooo             oooo     oooo          oooo           oooo                    oooo              oooo\n\r ooooo         ooooo      oooo        ooooo             oooo           ooNo    oooo              oooo\n\r   oooooooooooooooo       oooo      ooooooooooooooo      oooooo      ooooo     oooo              oooo\n\r      oooooooooo          oooo     ooooooooooooooooo       ooooooooOoooo       oooo              oooo\n\r');
});